<template>
    <div class="row">
        <div class="col-12" >
            <g-loading v-if="firstLoading" />
            <g-table v-else :filter="filterFormModel" :itemCount="itemCount" :items="items" :fields="fields" @load="load" @reset="reset"
                     title="Lịch sử truy cập" titleIcon="fa fa-history">
                <template slot="filter">
                    <time-range-picker class="mr-1" :model="filterFormModel" label="Thời gian" fromAttribute="from_time" toAttribute="to_time" :defaultShortcut="10" @change="search" />
                    <staff-select placeHolder="Chọn nhân viên"  :isFilter="true"  :model="filterFormModel"  @change="search" attribute="staff_id"  />
                </template>
                <template #cell(name)="{item}">
                    <span v-if="item.staff_id && params.staffs[item.staff_id]">{{ params.staffs[item.staff_id].name}}</span> 
                </template>
                <template #cell(customeTime)="{item}">
                    <div>{{item.time * 1000 | datetimeFormat}}</div> 
                </template>
            </g-table>
        </div>
        <b-modal v-model="showForm" :title="formTitle" @ok="save" ok-title="Lưu lại" cancel-title="Huỷ" no-close-on-backdrop no-enforce-focus>

            <form-input label="Họ và tên" :model="formModel"   :errors="formErrors" attribute="fullname" ></form-input>
            <form-input label="Email" :model="formModel" :required='true' :errors="formErrors" attribute="email" ></form-input>
            <form-select @change="changeType()" label="Loại đăng ký" :required='true' :model="formModel" attribute="type" :errors="formErrors" :options="$params.subscribeOptions"  />

            <post-select v-if="formModel.type == '2'" label="Bài viết"  :model="formModel" :errors="formErrors" attribute="target_id" :required='true' />
            <doc-select  v-if="formModel.type == '3'"  label="Tài liệu"  :model="formModel" :errors="formErrors" attribute="target_id" :required='true' />
            <doc-topic-select v-if="formModel.type == '4'"  label="Chủ đề"  :model="formModel" :errors="formErrors" attribute="target_id" :required='true' />

            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button variant="secondary" class="float-right" @click="cancel">Đóng</b-button>
                <b-button :disabled="saveLoading" variant="primary" class="float-right" @click="ok">
                    <b-spinner v-if="saveLoading" small></b-spinner>
                    <em v-else class="fa fa-save" ></em>
                    Lưu lại
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import list from "@/mixins/List";
    import crud from '@/mixins/CRUD';
    import role from '@/mixins/Role';
    import StaffSelect from '@/components/StaffSelect';
    export default {
        components: {StaffSelect},
        mixins: [list, crud,role],
        data: function () {
            return {
                service: '/staff-activity',
                fields: [
                    {key: 'name', label: "Nhân viên"},
                    {key: 'customeTime', label: "Truy cập lúc"},
                    {key: 'request_method', label: "Phương thức", class: 'text-center'},
                    {key: 'request_url', label: "Url", class: 'text-left'},
                    {key: 'action', label: 'Hành động'}
                ],
                defaultFilterFormModel: {
                    // keyword: '',
                    // type: null
                    staff_id : null,
                },
                defaultFormModel: {
                    // type: null
                },
            };
        },
        methods: {
            changeType(){
                this.formModel.target_id = null;
            }

        }
    }
</script>